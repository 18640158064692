import { all, call, put, takeLatest } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import { adminActions } from "../actions";
import { OrganizationReportDto } from "../../../types/dto/OrganizationReportDto";
import { toast } from "react-toastify";
import { authSaga } from "../../authentication/getAuthStatus";
import organizationAPI from "../../../services/organizationServices";
import { AxiosResponse } from "axios";

export function* getOrganizationReports(
  action: ActionType<typeof adminActions.organizationReports.get>
) {
  try {
    const organizationsReports: AxiosResponse<OrganizationReportDto[]> = yield call(
      organizationAPI.fetchOrganizationReports,
      action.payload
    );
    yield put(
      adminActions.organizationReports.add(
        organizationsReports.data,
        action.payload
      )
    );
  } catch (error) {
    toast.error(error.message, { position: "top-center" });
  }
}

export function* newOrganizationReport(
  action: ActionType<typeof adminActions.organizationReports.new>
) {
  try {
    yield call(
      organizationAPI.addOrganizationReport,
      action.payload.organizationName,
      action.payload.reportNameCode
    );
    yield put(
      adminActions.organizationReports.update(
        action.payload,
        action.payload.organizationName
      )
    );
  } catch (error) {
    toast.error(error.message);
  }
  yield action.meta.cb();
}

export function* deleteOrganizationReport(
  action: ActionType<typeof adminActions.organizationReports.delete>
) {
  try {
    yield call(
      organizationAPI.removeOrganizationReport,
      action.payload.organizationName,
      action.payload.reportName
    );
    yield put(
      adminActions.organizationReports.remove(
        action.payload.organizationName,
        action.payload.reportName
      )
    );
  } catch (error) {
    toast.error(error.message);
  }
  yield action.meta();
}

export function* requestReportAccess(
  action: ActionType<
    typeof adminActions.organizationReports.requestReportAccess
  >
) {
  try {
    yield call(
      organizationAPI.requestReportAccess,
      action.payload.organizationName,
      action.payload.reportName
    );
    yield toast.success("Access has been requested!");
  } catch (error) {
    toast.error(error.message);
  }
}

function* organizationReportsSagaWatcher() {
  yield all([
    takeLatest(
      getType(adminActions.organizationReports.get),
      authSaga(getOrganizationReports)
    ),
    takeLatest(
      getType(adminActions.organizationReports.new),
      authSaga(newOrganizationReport)
    ),
    takeLatest(
      getType(adminActions.organizationReports.delete),
      authSaga(deleteOrganizationReport)
    ),
    takeLatest(
      getType(adminActions.organizationReports.requestReportAccess),
      authSaga(requestReportAccess)
    ),
  ]);
}

export default organizationReportsSagaWatcher;
