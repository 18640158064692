import styled from "styled-components";
import color from "../../../utils/color";
import search from "../../../media/svg/search.svg";

const ContentSearch = styled.input<{ width?: number }>`
  width: ${({ width = 250 }) => width}px;
  margin-bottom: 10px;
  border: none;
  border-radius: 16px;
  font-size: 15px;
  color: ${color.NEUTRAL_DARK};
  background-color: ${color.BACKGROUND_SECONDARY};
  background-image: url(${search});
  background-position: 5px 5px;
  background-repeat: no-repeat;
  padding: 5px 10px 5px 35px;

  &:focus {
    outline: none;
    box-shadow: 0 0 4px 1px ${color.PRIMARY};
  }
`;

export default ContentSearch;
