import axios from "axios";
import { Organization } from "../types/Organization";
import { UserData } from "../ducks/admin/users/sagas";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_API_URL}/organization/`,
  timeout: 20000,
});

const newAdminApi = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_API_URL}/admin/`,
  timeout: 20000,
});

const organizationAPI = {
  fetchOrganizations: () => api.get("?isOrganizationAdmin=true"),

  deleteOrganization: (name: string) => api.delete(name),

  createOrganization: (organization: Organization) =>
    api.post("", organization),

  updateOrganization: (organization: Organization, organizationName: string) =>
    api.put(organizationName, organization),

  fetchOrganizationReports: (organizationName: string) =>
    api.get(`${organizationName}/reports`),

  addOrganizationReport: (organizationName: string, reportName: string) =>
    api.post(`${organizationName}/reports/${encodeURIComponent(reportName)}`),

  removeOrganizationReport: (organizationName: string, reportName: string) =>
    api.delete(`${organizationName}/reports/${encodeURIComponent(reportName)}`),

  requestReportAccess: (organizationName: string, reportName: string) =>
    newAdminApi.post(
      `/organization/${organizationName}/reports/${reportName}/request`
    ),

  fetchOrganizationUsers: (organizationName: string) =>
    api.get(`${organizationName}/users`),

  addOrganizationUser: (organizationName: string, userName: string) =>
    api.post(`${organizationName}/users/${userName}`),

  deleteOrganizationUser: (organizationName: string, userName: string) =>
    api.delete(`${organizationName}/users/${userName}`),

  addUserRole: (organizationName: string, userName: string, role: string) =>
    api.post(`${organizationName}/users/${userName}/roles/${role}`),

  removeUserRole: (organizationName: string, userName: string, role: string) =>
    api.delete(`${organizationName}/users/${userName}/roles/${role}`),

  fetchOrganizationViews: (organizationName: string) =>
    api.get(`/${organizationName}/views`),

  addOrganizationView: (organizationName: string, viewName: string) =>
    api.post(`/${organizationName}/views/${viewName}`),

  removeOrganizationView: (organizationName: string, viewName: string) =>
    api.delete(`/${organizationName}/views/${viewName}`),

  requestViewAccess: (organizationName: string, viewName: string) =>
    newAdminApi.post(
      `/organization/${organizationName}/views/${viewName}/request`
    ),

  addAllViews: (organizationName: string) => {
    api.post(`/${organizationName}/views/all`);
  },

  fetchUsers: () => api.get("user"),

  deleteUser: (id: string) => api.delete(`user?userId=${id}`),

  createUser: (data: UserData) => newAdminApi.post("user", data),

  updateUser: (userId: string, data: UserData) =>
    api.put(`user?userId=${userId}`, data),

  getCalendarReports: (organization: string, calendarName: string) =>
    api.get(`${organization}/calendars/${calendarName}/reports`),

  addCalendarReport: (
    organization: string,
    calendarName: string,
    report: string
  ) => api.post(`${organization}/calendars/${calendarName}/reports/${report}`),

  deleteCalendarReport: (
    organization: string,
    calendarName: string,
    report: string
  ) =>
    api.delete(`${organization}/calendars/${calendarName}/reports/${report}`),

  fetchCalendars: (org: string) => api.get(`${org}/calendars`),

  createCalendar: (organizationName: string, name: string) =>
    api.post(`${organizationName}/calendars`, { organizationName, name }),

  deleteCalendar: (organizationName: string, name: string) =>
    api.delete(`${organizationName}/calendars/${name}`),

  updateCalendar: (organizationName: string, oldName: string, name: string) =>
    api.put(`${organizationName}/calendars/${oldName}`, {
      organizationName,
      name,
    }),

  fetchVerificationUrl: (userId: string) =>
    api.get(`/user/${encodeURIComponent(userId)}/verification/url`),

  resendInvitation: (userId: string) =>
    newAdminApi.get(`/account/${userId}/verification/resend`),
};

export default organizationAPI;
