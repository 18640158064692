import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import registerServiceWorker from "./registerServiceWorker";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./ducks/store";
import callback from "./callback";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/callback/:type" component={callback} />
        <Route path="/" component={App} />
      </Switch>
    </BrowserRouter>
    <ToastContainer />
  </Provider>,
  document.getElementById("root") as HTMLElement
);
registerServiceWorker();
