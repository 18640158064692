import * as React from "react";
import Button from "src/components/shared/Button/Button";
import color from "src/utils/color";
import styled from "styled-components";
import subHeroSvg from "../../media/svg/sub-hero.svg";
import { FaCheckCircle } from "react-icons/fa";
import { LuMilk } from "react-icons/lu";
import { GiGrainBundle } from "react-icons/gi";
import { FaCow } from "react-icons/fa6";
import { RouteComponentProps, Link } from "react-router-dom";
import { CategoryRouteState, RouteCategories } from "./landingPage.types";

type Props = RouteComponentProps;

const Hero = styled.div`
  height: calc(100vh - 100px);
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color.TEXT_PRIMARY};
  background-color: ${color.BACKGROUND};
`;

const Section = styled.div`
  text-align: center;
`;

const Content = styled.div`
  > * + * {
    margin-top: 2.5rem;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.65rem
  font-family: "Open Sans", sans-serif;
`;

const Description = styled.p`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2rem;
`;

const DataCategoriesWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 0.75rem;

  @media (max-width: 565px) {
    flex-direction: column;
    align-items: center;
  }
`;
const DataCategory = styled.button`
  border: 1px solid ${color.PRIMARY};
  border-radius: 1rem;
  padding: 0.25rem 1rem;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.9rem;
  color: ${color.TEXT_PRIMARY};
  background-color: ${color.BACKGROUND};
  cursor: pointer;

  &:hover {
    box-shadow: 2px 2px 2px rgba(128, 128, 128, 0.5);
    transform: perspective(1px) translateZ(0);
    transition-duration: 0.3s;
    transition-property: box-shadow;
  }
`;

const SubHero = styled.section`
  background-color: #6eb23f1a;
  display: flex;
  padding: 10rem 2.5rem;
  color: ${color.TEXT_PRIMARY};

  @media (max-width: 855px) {
    flex-direction: column;
    text-align: center;
    padding: 2.5rem;
  }
`;
const SubHeroImageWrapper = styled.div`
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Image = styled.img`
  width: 75%;

  @media (max-width: 565px) {
    width: 50%%;
  }
`;
const SubHeroDescriptionWrapper = styled.div`
  flex: 1 1 0%;
  padding: 2rem;
`;
const SubHeroDescription = styled.h2`
  font-size: 2.625rem;
  font-weight: 700;
  line-height: 3.2rem;
`;
const SubHeroBulletsWrapper = styled.div`
  > * + * {
    margin-top: 1.5rem;
  }

  @media (max-width: 855px) {
    text-align: left;
  }
`;
const SubHeroBullet = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2rem;

  @media (max-width: 565px) {
    font-size: 0.75rem;
  }
`;

const LandingPageScene: React.FC<Props> = ({ history }) => {
  function handleCategoryClick(category: RouteCategories) {
    const categoryRouteState: CategoryRouteState = { section: category };
    history.push("/udm-api-subscription", categoryRouteState);
  }

  return (
    <>
      <Hero className="montserrat-landing-page-font">
        <Section>
          <Content>
            <section>
              <Title>
                Making <span style={{ color: color.PRIMARY }}>Data</span> Fun.
              </Title>
              <span
                style={{
                  fontWeight: 700,
                  fontSize: "1.5rem",
                  lineHeight: "1.8rem",
                }}
              >
                in a serious way
              </span>
            </section>
            <Description>
              UDM is a one-stop shop for all things data.
              <br />
              Explore our data categories:
            </Description>
            <DataCategoriesWrapper>
              <DataCategory
                type="button"
                onClick={() => handleCategoryClick("dairy-markets")}
              >
                <LuMilk />
                Dairy Markets
              </DataCategory>
              <DataCategory
                type="button"
                onClick={() => handleCategoryClick("grain-feed-markets")}
              >
                <GiGrainBundle />
                Grain/Feed Markets
              </DataCategory>
              <DataCategory
                type="button"
                onClick={() => handleCategoryClick("livestock-markets")}
              >
                <FaCow />
                Livestock Markets
              </DataCategory>
            </DataCategoriesWrapper>
            <section>
              <Link to="/contact-us">
                <Button type="button" filled>
                  Contact Us
                </Button>
              </Link>
            </section>
          </Content>
        </Section>
      </Hero>
      <SubHero className="montserrat-landing-page-font">
        <SubHeroImageWrapper>
          <Image src={subHeroSvg} />
        </SubHeroImageWrapper>
        <SubHeroDescriptionWrapper>
          <SubHeroDescription>
            One <span style={{ color: color.PRIMARY }}>hub</span> for dairy and
            livestock market data
          </SubHeroDescription>
          <SubHeroBulletsWrapper>
            <SubHeroBullet>
              <div>
                <FaCheckCircle color={color.PRIMARY} size="1.5rem" />
              </div>
              Aggregate large amounts of data from global sources
            </SubHeroBullet>
            <SubHeroBullet>
              <div>
                <FaCheckCircle color={color.PRIMARY} size="1.5rem" />
              </div>
              Data is cleaned and cataloged into relevant formats for use in
              Excel or ingestion to internal database
            </SubHeroBullet>
            <SubHeroBullet>
              <div>
                <FaCheckCircle color={color.PRIMARY} size="1.5rem" />
              </div>
              Saves time and hassle for organizations by creating one hub for
              market data
            </SubHeroBullet>
            <SubHeroBullet>
              <div>
                <FaCheckCircle color={color.PRIMARY} size="1.5rem" />
              </div>
              Consolidate even more data easily
            </SubHeroBullet>
          </SubHeroBulletsWrapper>
        </SubHeroDescriptionWrapper>
      </SubHero>
    </>
  );
};

export default LandingPageScene;
