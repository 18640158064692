import { RootState } from "../store";
import { Organization } from "../../types/Organization";
import { User } from "../../types/User";
import { createSelector } from "reselect";
import {
  getActivePageFromProps,
  getSelectedCalendarFromProps,
  getPerPageFromProps,
  getSearchFromProps,
  getOrganizationNameFromProps,
  getSortFromProps,
  getDirectionFromProps,
  getShowTranslatedNameFromProps,
} from "../fromPropsSelector";
import containsString from "../../utils/containsString";
import {
  getAdminPageOrganization,
  getAdminPageSearch,
} from "../adminPage/selectors";
import sortObjectStringsByKey from "../../utils/sortObjectStringsByKey";
import { getAuthState } from "../authentication/selectors";
import { AuthState } from "../authentication/reducers";

export const getOrganizations = (state: RootState): Organization[] =>
  state.admin.organization;

export const getUsers = (state: RootState): User[] => state.admin.user;

export const getOrganizationReports = (state: RootState) =>
  state.admin.organizationReports;

export const getReports = (state: RootState) => state.admin.report;

export const getAllCalendars = (state: RootState) => state.admin.calendars;

export const getAllCalendarReport = (state: RootState) =>
  state.admin.calendarReports;

export const getAllOrganizationsUsers = (state: RootState) =>
  state.admin.organizationUsers;

export const getSubsAndViews = (state: RootState) => state.admin.subscriptions;

export const getActiveSubscriptions = createSelector(
  getSubsAndViews,
  (subsAndViews) => {
    const activeSubsSet = new Set<string>();
    subsAndViews.subscriptions.forEach(({ viewName }) => {
      activeSubsSet.add(viewName);
    });
    return [...activeSubsSet];
  }
);

export const getViewsForDisplay = createSelector(
  getSubsAndViews,
  getActivePageFromProps,
  getPerPageFromProps,
  getAdminPageSearch,
  getSortFromProps,
  (subsAndViews, activePage, perPage, search, sortBy) => {
    const filteredViews = subsAndViews.views.filter((v) =>
      containsString(v, search)
    );
    const sortedViews = filteredViews.sort(
      sortBy === "A - Z"
        ? (a, b) => a.localeCompare(b)
        : (a, b) => b.localeCompare(a)
    );
    return sortedViews.slice((activePage - 1) * perPage, perPage * activePage);
  }
);

export const getSelectedOrganizationCalendars = createSelector(
  getAllCalendars,
  getAdminPageOrganization,
  (allCalendars, selectedOrganization) =>
    allCalendars[selectedOrganization] || []
);

export const getOrganizationCalendars = createSelector(
  getSelectedOrganizationCalendars,
  getActivePageFromProps,
  getPerPageFromProps,
  getAdminPageSearch,
  (calendars, paginationActivePage, paginationPerPage, search) => {
    const filteredCalendars = calendars.filter((calendar) =>
      containsString(calendar.name, search)
    );
    return filteredCalendars.slice(
      (paginationActivePage - 1) * paginationPerPage,
      paginationPerPage * paginationActivePage
    );
  }
);

export const getAllViews = (state: RootState) => state.admin.view.allViews;

export const getAllOrganizationViews = (state: RootState) =>
  state.admin.view.organizationViews;

export const getOrganizationViews = createSelector(
  getAllOrganizationViews,
  getAdminPageOrganization,
  (organizationViews, organizationName) =>
    organizationViews[organizationName]
      ? organizationViews[organizationName]
      : []
);

export const getFilteredRecords = createSelector(
  getReports,
  getSearchFromProps,
  (reports, search) =>
    reports.filter(({ name }) => containsString(name, search))
);

export const getCalendarReports = createSelector(
  getAllCalendarReport,
  getFilteredRecords,
  getSelectedCalendarFromProps,
  getAdminPageOrganization,
  getActivePageFromProps,
  getPerPageFromProps,
  (
    allCalendarReports,
    reports,
    calendarName,
    organization,
    paginationActivePage,
    paginationPerPage
  ) => {
    if (
      !calendarName ||
      !organization ||
      !allCalendarReports[organization] ||
      !allCalendarReports[organization][calendarName]
    ) {
      return [];
    }
    const calendarReports = allCalendarReports[organization][calendarName];

    return reports
      .slice(
        (paginationActivePage - 1) * paginationPerPage,
        paginationPerPage * paginationActivePage
      )
      .map(({ name }) => ({
        name,
        added:
          calendarReports.findIndex(
            (calendarReport) => calendarReport.name === name
          ) !== -1,
      }));
  }
);

export const getFilteredOrganizations = createSelector(
  getOrganizations,
  getActivePageFromProps,
  getPerPageFromProps,
  getAdminPageSearch,
  getSortFromProps,
  getDirectionFromProps,
  (
    organizations,
    paginationActivePage,
    paginationPerPage,
    search,
    sortBy,
    sortDirection
  ) => {
    let sortedOrganizations: Organization[] = [];
    if (
      organizations.length &&
      organizations[0][sortBy as keyof Organization] !== undefined
    ) {
      sortedOrganizations = [...organizations].sort(
        sortObjectStringsByKey(sortBy)
      );
    } else {
      sortedOrganizations = [...organizations].sort(
        sortObjectStringsByKey("fullName")
      );
    }
    if (sortDirection === "descending") {
      sortedOrganizations.reverse();
    }
    const filteredOrganizations = sortedOrganizations.filter(
      (organization) =>
        containsString(organization.name, search) ||
        containsString(organization.fullName, search)
    );
    return filteredOrganizations.slice(
      (paginationActivePage - 1) * paginationPerPage,
      paginationPerPage * paginationActivePage
    );
  }
);

export const getETLReports = createSelector(
  getOrganizationReports,
  getReports,
  getActivePageFromProps,
  getPerPageFromProps,
  getAdminPageOrganization,
  getAdminPageSearch,
  getSortFromProps,
  getDirectionFromProps,
  getShowTranslatedNameFromProps,
  (
    organizationReports,
    reports,
    paginationActivePage,
    paginationPerPage,
    selectedOrg,
    search,
    sortBy,
    sortDirection,
    showTranslatedName
  ) => {
    if (!selectedOrg) {
      return [];
    }
    const orgReports = organizationReports[selectedOrg];
    if (!orgReports) {
      return [];
    }
    const filteredReports = reports.filter(({ name, nameTranslated }) =>
      containsString(showTranslatedName ? nameTranslated : name, search)
    );

    const mappedOrganizationReports = filteredReports.map(
      ({ code, name, requestAccess, nameTranslated }) => ({
        nameTranslated,
        code,
        name,
        requestAccess,
        added:
          orgReports.findIndex(
            (orgReport) => orgReport.reportNameCode === code
          ) !== -1,
      })
    );

    let sortedReports: Array<{
      code: string;
      name: string;
      requestAccess: boolean;
      added: boolean;
      nameTranslated: string;
    }> = [];

    if (sortBy.length !== 0) {
      sortedReports = [...mappedOrganizationReports].sort(
        sortObjectStringsByKey(sortBy)
      );
    } else {
      sortedReports = [...mappedOrganizationReports].sort(
        sortObjectStringsByKey("name")
      );
    }
    if (
      (sortDirection === "descending" &&
        !(sortBy === "dateCreated" || sortBy === "added")) ||
      ((sortBy === "dateCreated" || sortBy === "added") &&
        sortDirection !== "descending")
    ) {
      sortedReports.reverse();
    }

    return sortedReports.slice(
      (paginationActivePage - 1) * paginationPerPage,
      paginationPerPage * paginationActivePage
    );
  }
);

export const getFilteredOrganizationViews = createSelector(
  getAllViews,
  getAllOrganizationViews,
  getAdminPageOrganization,
  getAdminPageSearch,
  getSortFromProps,
  getDirectionFromProps,
  (
    allViews,
    organizationViews,
    selectedOrganization,
    search,
    sortBy = "",
    sortDirection
  ) => {
    if (!organizationViews || !selectedOrganization) {
      return [];
    }
    const selectedOrganizationViews = organizationViews[selectedOrganization];
    if (!selectedOrganizationViews) {
      return [];
    }

    const filteredViews = allViews.filter(({ displayName }) =>
      containsString(displayName, search)
    );
    const mappedOrganizationViews = filteredViews.map(
      ({ name, displayName, dateCreated, requestAccess }) => ({
        name,
        displayName,
        dateCreated,
        requestAccess,
        added:
          selectedOrganizationViews.findIndex(
            (orgView) => orgView.viewName === name
          ) !== -1,
      })
    );
    let sortedViews: Array<{
      name: string;
      displayName: string;
      added: boolean;
      requestAccess: boolean;
    }> = [];
    if (sortBy.length !== 0) {
      sortedViews = [...mappedOrganizationViews].sort(
        sortObjectStringsByKey(sortBy)
      );
    } else {
      sortedViews = [...mappedOrganizationViews].sort(
        sortObjectStringsByKey("dateCreated")
      );
    }
    if (
      (sortDirection === "descending" &&
        !(sortBy === "dateCreated") &&
        !(sortBy === "added")) ||
      ((sortBy === "dateCreated" || sortBy === "added") &&
        sortDirection !== "descending") ||
      !sortBy.length
    ) {
      sortedViews.reverse();
    }
    return sortedViews;
  }
);

export const getMemberOrganizationViews = createSelector(
  getFilteredOrganizationViews,
  (memberOrgViews) => memberOrgViews.filter((mov) => mov.added)
);

export const getSlicedMemberOrganizationViews = createSelector(
  getMemberOrganizationViews,
  getActivePageFromProps,
  getPerPageFromProps,
  (filteredMemberOrganizationViews, paginationActivePage, paginationPerPage) =>
    filteredMemberOrganizationViews.slice(
      (paginationActivePage - 1) * paginationPerPage,
      paginationPerPage * paginationActivePage
    )
);

export const getSlicedOrganizationViews = createSelector(
  getFilteredOrganizationViews,
  getActivePageFromProps,
  getPerPageFromProps,
  (filteredOrganizationViews, paginationActivePage, paginationPerPage) =>
    filteredOrganizationViews.slice(
      (paginationActivePage - 1) * paginationPerPage,
      paginationPerPage * paginationActivePage
    )
);

export const getOrganizationUsers = createSelector(
  getOrganizationNameFromProps,
  getAllOrganizationsUsers,
  (organizationName, organizationUsers) =>
    organizationUsers[organizationName] || []
);

export const getUsersDisplayed = createSelector(
  getUsers,
  getOrganizationUsers,
  getAuthState,
  getAdminPageOrganization,
  (users, orgUsers, auth, selectedOrganization) => {
    if (auth === AuthState.administrator) {
      return users;
    }
    const userList = orgUsers.filter(
      (ou) => ou.organizationName === selectedOrganization
    );
    return users.filter((u) => userList.some((ul) => u.userId === ul.userId));
  }
);

export const getFilteredUsers = createSelector(
  getUsersDisplayed,
  getActivePageFromProps,
  getPerPageFromProps,
  getAdminPageSearch,
  getSortFromProps,
  getDirectionFromProps,
  (
    users,
    paginationActivePage,
    paginationPerPage,
    search,
    sortBy,
    sortDirection
  ) => {
    let sorted = [];
    if (users.length && users[0][sortBy as keyof User] !== undefined) {
      sorted = [...users].sort(sortObjectStringsByKey(sortBy));
    } else {
      sorted = [...users].sort(sortObjectStringsByKey("firstName"));
    }
    if (sortDirection === "descending") {
      sorted.reverse();
    }
    const filteredUsers = sorted.filter(
      ({ firstName, lastName, email }) =>
        containsString(firstName, search) ||
        containsString(lastName, search) ||
        containsString(email, search)
    );
    return filteredUsers.slice(
      (paginationActivePage - 1) * paginationPerPage,
      paginationPerPage * paginationActivePage
    );
  }
);

export const getModalFilteredUsers = createSelector(
  getUsers,
  getSearchFromProps,
  (users, search) =>
    users.filter(
      ({ firstName, lastName, email }) =>
        containsString(firstName, search) ||
        containsString(lastName, search) ||
        containsString(email, search)
    )
);

export const getModalSlicedUsers = createSelector(
  getModalFilteredUsers,
  getOrganizationUsers,
  getActivePageFromProps,
  getPerPageFromProps,
  getSortFromProps,
  getDirectionFromProps,
  (
    filteredUsers,
    organizationUsers,
    paginationActivePage,
    paginationPerPage,
    sortBy,
    sortDirection
  ) => {
    const users = filteredUsers.map((filteredUser) => {
      const organizationUser = organizationUsers.find(
        (orgUser) =>
          orgUser.userId.toLowerCase() === filteredUser.userId.toLowerCase()
      );
      return {
        ...filteredUser,
        isAdmin: organizationUser ? organizationUser.isAdmin : false,
        added: !!organizationUser,
      };
    });
    if (users.length && users[0][sortBy as keyof User] !== undefined) {
      users.sort(sortObjectStringsByKey(sortBy));
    } else {
      users.sort(sortObjectStringsByKey("fullName"));
    }
    if (sortDirection === "descending") {
      users.reverse();
    }
    return users.slice(
      (paginationActivePage - 1) * paginationPerPage,
      paginationPerPage * paginationActivePage
    );
  }
);

// TODO: get better way to get data length
export const getDataLength = createSelector(
  getUsersDisplayed,
  getOrganizations,
  getReports,
  getAllViews,
  getSelectedOrganizationCalendars,
  getAdminPageSearch,
  (users, organizations, reports, views, calendars, search) => ({
    usersLength: users.filter(
      ({ firstName, lastName, email }) =>
        containsString(firstName, search) ||
        containsString(lastName, search) ||
        containsString(email, search)
    ).length,
    organizationsLength: organizations.filter((organization) =>
      containsString(organization.name, search)
    ).length,
    reportsLength: reports.filter(({ name }) => containsString(name, search))
      .length,
    viewsLength: views.filter(({ displayName }) =>
      containsString(displayName, search)
    ).length,
    calendarsLength: calendars.filter(({ name }) =>
      containsString(name, search)
    ).length,
  })
);
