import styled from "styled-components";
import color from "../../../utils/color";

type Props = { maxWidth?: string };

const TD = styled.td<Props>`
  padding: 10px 20px 10px 0;
  color: ${color.TEXT_PRIMARY};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth.trim() : "550px")};
  word-wrap: break-word;
  border-bottom: ${color.NEUTRAL} solid 1px;
`;

export default TD;
