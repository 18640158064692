import { ActionType, createStandardAction } from "typesafe-actions";
import { Organization } from "../../types/Organization";
import { OrganizationUser, User } from "../../types/User";
import { OrganizationReport } from "../../types/OrganizationReport";
import { Report } from "../../types/Report";
import { RequestView, View } from "../../types/View";
import { OrganizationView } from "../../types/OrganizationView";
import { Calendar } from "../../types/Calendar";
import { CalendarReport } from "../../types/CalendarReport";
import { OrganizationUserDto } from "../../types/dto/OrganizationUserDto";
import { Subscription } from "src/types/Subscription";

type UserPayload = {
  firstName: string;
  lastName: string;
  email: string;
};

type CalendarReportsPayload = {
  organization: string;
  calendarName: string;
};

export type UserErrors = {
  Email?: string[];
  FirstName?: string[];
  LastName?: string[];
  Password?: string[];
};

export type CreateUserErrors = {
  errors?: {
    Email?: Array<string>;
    FirstName?: Array<string>;
    LastName?: Array<string>;
  };
  status?: number;
  title?: string;
  traceId?: string;
  type?: string;
  User?: Array<string>;
};

export const adminActions = {
  organization: {
    get: createStandardAction("@admin_organization/GET")(),
    add: createStandardAction("@admin_organization/ADD")<Organization>(),
    addAll: createStandardAction("@admin_organization/ADD_ALL")<
      Organization[]
    >(),
    delete: createStandardAction("@admin_organization/DELETE")<string>(),
    remove: createStandardAction("@admin_organization/REMOVE")<string>(),
    new: createStandardAction("@admin_organization/NEW")<
      Organization,
      (error?: string) => void
    >(),
    edit: createStandardAction("@admin_organization/EDIT")<
      Organization,
      { name: string; cb: (error?: string) => void }
    >(),
    update: createStandardAction("@admin_organization/UPDATE")<
      Organization,
      string
    >(),
  },
  organizationUsers: {
    getUsers: createStandardAction("@admin_organization_users/GET")<string>(),
    saveUsers: createStandardAction("@admin_organization_users/SAVE")<{
      organizationName: string;
      users: OrganizationUserDto[];
    }>(),
    addUser: createStandardAction("@admin_organization_users/ADD")<{
      organizationName: string;
      user: OrganizationUser;
    }>(),
    saveUser: createStandardAction("@admin_organization_users/SAVE_USER")<{
      organizationName: string;
      user: OrganizationUser;
    }>(),
    getUser: createStandardAction(`@admin_organization_users/GET`)<{
      organizationName: string;
      userName: string;
    }>(),
    removeUser: createStandardAction("@admin_organization_users/REMOVE")<{
      organizationName: string;
      userName: string;
    }>(),
    deleteUser: createStandardAction("@admin_organization_users/DELETE")<{
      organizationName: string;
      userName: string;
    }>(),
    removeOrganization: createStandardAction(
      "@admin_organization_users/REMOVE_ORGANIZATION"
    )<string>(),
    addAdminUser: createStandardAction(
      "@admin_organization_users/ADD_ADMIN_USER"
    )<{ organizationName: string; user: OrganizationUser }>(),
    addUserRole: createStandardAction(
      "@admin_organization_users/ADD_USER_ROLE"
    )<{ organizationName: string; userName: string; role: string }>(),
    addAdminUserRole: createStandardAction(
      "@admin_organization_users/EDIT_USER_ROLE"
    )<{ organizationName: string; userName: string; role: string }>(),
    removeUserRole: createStandardAction(
      "@admin_organization_users/REMOVE_USER_ROLE"
    )<{ organizationName: string; userName: string; role: string }>(),
    removeAdminUserRole: createStandardAction(
      "@admin_organization_users/REMOVE_ADMIN_USER_ROLE"
    )<{ organizationName: string; userName: string }>(),
  },
  users: {
    get: createStandardAction("@admin_users/GET")(),
    add: createStandardAction("@admin_users/ADD")<User[]>(),
    addOne: createStandardAction("@admin_users/ADD_ONE")<User>(),
    delete: createStandardAction("@admin_users/DELETE")<string>(),
    remove: createStandardAction("@admin_users/REMOVE")<string>(),
    new: createStandardAction("@admin_users/NEW")<
      UserPayload & { organizationName: string },
      (error?: CreateUserErrors) => void
    >(),
    edit: createStandardAction("@admin_users/EDIT")<User, string>(),
    update: createStandardAction("@admin_users/UPDATE")<
      UserPayload,
      { userId: string; cb: (error?: UserErrors) => void }
    >(),
  },
  organizationReports: {
    get: createStandardAction("@admin_organizationReports/GET")<string>(),
    add: createStandardAction("@admin_organizationReports/ADD")<
      OrganizationReport[],
      string
    >(),
    update: createStandardAction("@admin_organizationReports/UPDATE")<
      OrganizationReport,
      string
    >(),
    delete: createStandardAction("@admin_organizationReports/DELETE")<
      { organizationName: string; reportName: string },
      () => void
    >(),
    remove: createStandardAction("@admin_organizationReports/REMOVE")<
      string,
      string
    >(),
    new: createStandardAction("@admin_organizationReports/NEW")<
      OrganizationReport,
      { e: (error?: string) => void; cb: () => void }
    >(),
    removeOrganization: createStandardAction(
      "admin_organizationReports/REMOVE_ORGANIZATION"
    )<string>(),
    requestReportAccess: createStandardAction(
      `@admin_organizationReports/REQUEST_ACCESS`
    )<{ organizationName: string; reportName: string }>(),
  },
  report: {
    get: createStandardAction("@admin_report/GET")(),
    add: createStandardAction("@admin_report/ADD")<Report[]>(),
  },
  view: {
    get: createStandardAction("@admin_view/GET")(),
    add: createStandardAction("@admin_view/ADD")<View[]>(),
  },
  organizationView: {
    get: createStandardAction("@admin_organization_view/GET")<string>(),
    addAll: createStandardAction("@admin_organization_view/ADD_ALL")<{
      organizationName: string;
      view: OrganizationView[];
    }>(),
    new: createStandardAction("@admin_organization_view/NEW")<{
      organizationName: string;
      view: RequestView;
    }>(),
    add: createStandardAction("@admin_organization_view/ADD")<{
      organizationName: string;
      view: RequestView;
    }>(),
    remove: createStandardAction("@admin_organization_view/REMOVE")<{
      organizationName: string;
      view: RequestView;
    }>(),
    delete: createStandardAction("@admin_organization_view/DELETE")<{
      organizationName: string;
      view: RequestView;
    }>(),
    removeOrganization: createStandardAction(
      "admin_organization_view/REMOVE_ORGANIZATION"
    )<string>(),
    addAllViews: createStandardAction("@admin_Organization_view/ADD_ALL_VIEWS")<
      string
    >(),
    saveAllViews: createStandardAction(
      "@admin_Organization_view/SAVE_ALL_VIEWS"
    )<string>(),
    requestViewAccess: createStandardAction(
      `@admin_organization_view/REQUEST_ACCESS`
    )<{ organizationName: string; view: RequestView }>(),
  },
  calendars: {
    get: createStandardAction("@admin_calendars/GET")<string>(),
    addAll: createStandardAction("@admin_calendars/ADD_ALL")<
      string,
      Calendar[]
    >(),
    add: createStandardAction("@admin_calendars/ADD")<string, Calendar>(),
    delete: createStandardAction("@admin_calendars/DELETE")<string, string>(),
    remove: createStandardAction("@admin_calendars/REMOVE")<string, string>(),
    new: createStandardAction("@admin_calendars/NEW")<
      Calendar,
      (error?: string) => void
    >(),
    edit: createStandardAction("@admin_calendars/EDIT")<
      Calendar,
      { name: string; cb: (error?: string) => void }
    >(),
    update: createStandardAction("@admin_calendars/UPDATE")<Calendar, string>(),
    removeOrganization: createStandardAction(
      "admin_calendars/REMOVE_ORGANIZATION"
    )<string>(),
  },
  calendarReports: {
    get: createStandardAction("@admin_calendarReports/GET")<
      CalendarReportsPayload
    >(),
    addAll: createStandardAction("@admin_calendarReports/ADD_ALL")<
      CalendarReportsPayload,
      CalendarReport[]
    >(),
    add: createStandardAction("@admin_calendarReports/ADD")<
      CalendarReportsPayload,
      string
    >(),
    new: createStandardAction("@admin_calendarReports/NEW")<
      CalendarReportsPayload,
      string
    >(),
    delete: createStandardAction("@admin_calendarReports/DELETE")<
      CalendarReportsPayload,
      string
    >(),
    remove: createStandardAction("@admin_calendarReports/REMOVE")<
      CalendarReportsPayload,
      string
    >(),
    removeCalendar: createStandardAction(
      "@admin_calendarReports/DELETE_CALENDAR"
    )<string, string>(),
    removeOrganization: createStandardAction(
      "@admin_calendarReports/DELETE_ORGANIZATION"
    )<string>(),
  },
  subscriptionsAndViews: {
    getSubs: createStandardAction("@admin_subscriptions/GET")(),
    populateSubs: createStandardAction("@admin_subscriptions/POPULATE")<
      Array<Subscription>
    >(),
    getViews: createStandardAction("@admin_views/GET")(),
    populateViews: createStandardAction("@admin_views/POPULATE")<
      Array<string>
    >(),
    addSub: createStandardAction("@admin_subscriptions/ADD_SUB")<
      Subscription
    >(),
    subscribe: createStandardAction("@admin_subscriptions/SUBSCRIBE")<{
      viewName: string;
    }>(),
    removeSub: createStandardAction("@admin_subscriptions/REMOVE_SUB")<
      string
    >(),
    unsubscribe: createStandardAction("@admin_subscriptions/UNSUBSCRIBE")<
      Subscription
    >(),
  },
};

export type AdminActions = ActionType<typeof adminActions>;
