import * as React from "react";
import styled from "styled-components";
import color from "../../utils/color";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import Logo from "../../media/svg/udm_logo.svg";
import { RootState } from "../../ducks/store";
import { getNavMenuItems } from "../../ducks/menu/selectors";
import { connect } from "react-redux";
import { authActions } from "../../ducks/authentication/actions";
import NavMenuHeader from "../../components/Menu/NavMenuHeader/NavMenuHeader";
import Button from "../../components/shared/Button/Button";
import { getAuthState } from "../../ducks/authentication/selectors";
import NavBarContent from "../../components/Menu/NavBarContent/NavBarContent";
import PublicNavLinks from "./PublicNavLinks";

const NavBarHeaderContainer = styled.div`
  background-color: ${color.BACKGROUND};
  z-index: 100;
`;

const HeaderContent = styled.div`
  padding: 13px 2rem;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavBar = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 9999;
  background-color: transparent;
  padding-left: 0;
`;

const LogoImg = styled.img`
  height: 36px;
  cursor: pointer;
`;

const LandingPageNavigationContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 3rem;
`;

const PrivateNavLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5rem;
`;

const FlexCC = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2rem;
`;

type OwnProps = { isLoggedIn: boolean };

type Props = RouteComponentProps &
  OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const NavBarContainer: React.FC<Props> = ({
  signIn,
  isLoggedIn,
  signOut,
  auth,
}) => (
  <NavBar>
    <NavBarHeaderContainer>
      <HeaderContent>
        <LandingPageNavigationContainer>
          <Link to="/">
            <LogoImg src={Logo} alt="Understanding Dairy Markets" />
          </Link>
          {!isLoggedIn && <PublicNavLinks />}
        </LandingPageNavigationContainer>
        {isLoggedIn ? (
          <PrivateNavLinksWrapper>
            {!(location.pathname === "/") && <NavBarContent />}
            <FlexCC>
              <NavMenuHeader signOut={signOut} auth={auth} />
            </FlexCC>
          </PrivateNavLinksWrapper>
        ) : (
          <Button width={135} height={32} filled onClick={signIn}>
            Sign in
          </Button>
        )}
      </HeaderContent>
    </NavBarHeaderContainer>
  </NavBar>
);

const mapStateToProps = (state: RootState) => ({
  navMenuItems: getNavMenuItems(state),
  auth: getAuthState(state),
});

const mapDispatchToProps = {
  signIn: authActions.signIn,
  signOut: authActions.signOut,
};

const connectedNavBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavBarContainer);
export default withRouter(connectedNavBarContainer);
