import produce from "immer";
import { adminActions, AdminActions } from "../actions";
import { Subscription } from "src/types/Subscription";
import { getType } from "typesafe-actions";

type ViewsAndSubs = {
  views: Array<string>;
  subscriptions: Array<Subscription>;
};

const initialState: ViewsAndSubs = {
  views: [],
  subscriptions: [],
};

export const subscriptionReducer = produce<ViewsAndSubs, AdminActions>(
  (state, action) => {
    switch (action.type) {
      case getType(adminActions.subscriptionsAndViews.populateSubs): {
        const newState = { ...state };
        newState.subscriptions = action.payload;
        return newState;
      }
      case getType(adminActions.subscriptionsAndViews.populateViews): {
        const newState = { ...state };
        newState.views = action.payload;
        return newState;
      }
      case getType(adminActions.subscriptionsAndViews.removeSub): {
        const newState = { ...state };
        newState.subscriptions = state.subscriptions.filter(
          (sub) => sub.viewName !== action.payload
        );
        return newState;
      }
      case getType(adminActions.subscriptionsAndViews.addSub): {
        const newState = { ...state };
        newState.subscriptions = [...state.subscriptions, action.payload];
        return newState;
      }
      default:
        return state;
    }
  },
  initialState
);
