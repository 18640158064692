import produce from "immer";
import { adminPageActions, AdminPageActions } from "./actions";
import { getType } from "typesafe-actions";

type AdminPageState = {
  search: string;
  organization: string;
};

const initialState = {
  search: "",
  organization: "",
};

export const adminPageReducer = produce<AdminPageState, AdminPageActions>(
  (state, action) => {
    switch (action.type) {
      case getType(adminPageActions.setSearch):
        state.search = action.payload;
        return;
      case getType(adminPageActions.setOrganization):
        state.organization = action.payload;
        return;
    }
  },
  initialState
);
