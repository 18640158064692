import * as React from "react";
import { connect } from "react-redux";
import PaginationWrapper from "src/components/AdminPanel/adminPanel/PaginationWrapper";
import Pagination from "src/components/shared/Pagination/Pagination";
import PerPage from "src/components/shared/Pagination/perPage";
import { adminActions } from "src/ducks/admin/actions";
import { getAdminPageSearch } from "src/ducks/adminPage/selectors";
import { RootState } from "src/ducks/store";
import styled from "styled-components";
import ViewMailNotificationsTable from "./ViewMailNotificationsTable";
import ContentSearch from "src/components/AdminPanel/adminPanel/ContentSearch";
import { adminPageActions } from "src/ducks/adminPage/actions";
import color from "src/utils/color";
import { getSubsAndViews } from "src/ducks/admin/selectors";
import SelectSection from "src/components/AdminPanel/adminPanel/SelectSection";
import SelectField from "src/components/shared/SelectField/SelectField";
import containsString from "src/utils/containsString";

const PageHeaderWrapper = styled.div`
  color: ${color.PRIMARY};
  margin-bottom: 1.5rem;
`;

const PageHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

function ViewMailNotificationsScene(props: Props) {
  const [perPage, setPerPage] = React.useState(10);
  const [activePage, setActivePage] = React.useState(1);
  const [sortBy, setSortBy] = React.useState("A - Z");

  function onPerPageSelect(event: React.ChangeEvent<HTMLSelectElement>) {
    const dataLength = props.subsAndViews.views.length;
    const lastPage = Math.ceil(dataLength / Number(event.target.value));
    if (activePage > lastPage) setActivePage(lastPage);
    setPerPage(Number(event.target.value));
  }

  function onPageSelect(value: number) {
    return () => setActivePage(value);
  }

  function handleOnSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    props.setAdminSearch(e.target.value);
    setActivePage(1);
  }

  function onSortChange(value: number) {
    const newSortBy = sortingOptions.find((so) => so.id === value);
    if (newSortBy) setSortBy(newSortBy.name);
  }

  function getPaginationLength() {
    if (!props.searchParameter.length) {
      return props.subsAndViews.views.length;
    }
    return props.subsAndViews.views.filter((v) =>
      containsString(v, props.searchParameter)
    ).length;
  }

  React.useEffect(function initRender() {
    props.setAdminSearch("");
    props.getViews();
    props.getSubscriptions();

    return () => {
      props.setAdminSearch("");
    };
  }, []);

  return (
    <div>
      <PageHeaderWrapper>
        <PageHeader>
          <h2 style={{ margin: 0 }}>View Mail Notifications</h2>
          <ContentSearch
            type="search"
            value={props.searchParameter}
            placeholder="Search for views"
            onChange={handleOnSearchChange}
            style={{ margin: 0 }}
          />
        </PageHeader>
      </PageHeaderWrapper>
      <SelectSection>
        <span>Sort By:</span>
        <SelectField
          options={sortingOptions}
          value={sortBy}
          onChange={onSortChange}
        />
      </SelectSection>
      <ViewMailNotificationsTable
        activePage={activePage}
        perPage={perPage}
        sort={sortBy}
      />
      <PaginationWrapper>
        <PerPage
          dataLength={props.subsAndViews.views.length}
          perPage={perPage}
          perPageClickHandler={onPerPageSelect}
        />
        <Pagination
          dataLength={getPaginationLength()}
          perPage={perPage}
          activePage={activePage}
          onClick={onPageSelect}
        />
      </PaginationWrapper>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  subsAndViews: getSubsAndViews(state),
  searchParameter: getAdminPageSearch(state),
});

const mapDispatchToProps = {
  getViews: adminActions.subscriptionsAndViews.getViews,
  getSubscriptions: adminActions.subscriptionsAndViews.getSubs,
  setAdminSearch: adminPageActions.setSearch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewMailNotificationsScene);

const sortingOptions = [
  { id: 3, name: "A - Z" },
  { id: 4, name: "Z - A" },
];
