import reduxSaga from "redux-saga";
import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import { StateType } from "typesafe-actions";
import rootSaga from "./rootSaga";
import { menuReducer } from "./menu/reducers";
import { recordsRootReducer } from "./records/reducers";
import { productReducer } from "./products/reducers";
import { productPageReducer } from "./productPage/reducers";
import { unitReducer } from "./units/reducers";
import { chartReducer } from "./charts/reducers";
import { adminRootReducer } from "./admin/reducers";
import { landingPageReducer } from "./landingPage/reducers";
import { adminPageReducer } from "./adminPage/reducers";
import { authenticationPageReducer } from "./authentication/reducers";
import { contextTableReducer } from "./contextTable/reducers";
import { catalogReducer } from "./catalog/reducers";

const sagaMiddleware = reduxSaga();

const middleware = [sagaMiddleware];

export const rootReducer = combineReducers({
  menu: menuReducer,
  records: recordsRootReducer,
  product: productReducer,
  productPage: productPageReducer,
  units: unitReducer,
  charts: chartReducer,
  admin: adminRootReducer,
  landingPage: landingPageReducer,
  adminPage: adminPageReducer,
  authentication: authenticationPageReducer,
  contextTable: contextTableReducer,
  catalog: catalogReducer,
});

const composeEnhancers =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export type RootState = StateType<typeof rootReducer>;

const store = createStore(
  rootReducer,
  process.env.NODE_ENV === "production"
    ? applyMiddleware(...middleware)
    : composeEnhancers(applyMiddleware(...middleware))
);

export default store;

sagaMiddleware.run(rootSaga);
