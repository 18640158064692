import styled from "styled-components";
import color from "../../../utils/color";

const HeaderCell = styled.th<{
  width?: string;
  textAlign?: "right" | "left" | "center";
}>`
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "left")};
  padding: 0 20px 10px 0;
  border-bottom: ${color.NEUTRAL} solid 2px;
  width: ${({ width = "auto" }) => width};

  svg {
    width: 25px;
    color: ${color.PRIMARY};
  }
`;

export default HeaderCell;
