import * as React from "react";
import color from "src/utils/color";
import styled from "styled-components";
import { FaCog, FaFileContract, FaStore } from "react-icons/fa";
import { PiBank, PiFactory } from "react-icons/pi";
import { LuGraduationCap } from "react-icons/lu";
import { GiTrade } from "react-icons/gi";
import { BsBank } from "react-icons/bs";
import { VscGraph } from "react-icons/vsc";
import { IoShieldCheckmark } from "react-icons/io5";

const Root = styled.section`
  color: ${color.TEXT_PRIMARY};

  > * + * {
    margin-top: 5rem;
  }
`;

const Heading = styled.h2`
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3rem;
  color: ${color.PRIMARY};
  border-bottom: 1px solid ${color.PRIMARY};
  width: 23rem;
`;

const AboutUsContainer = styled.section`
  > * + * {
    margin-top: 2.5rem;
  }
`;

const MemberWrapper = styled.div`
  width: 75%;
  text-align: justify;

  @media (max-width: 815px) {
    width: 100%;
  }
`;
const MemberName = styled.div`
  color: ${color.PRIMARY};
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
`;
const MemberRole = styled.div`
  color: black;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2rem;
`;
const MemberInfo = styled.div`
  margin-top: 0.5rem;
`;

const WhoWeServeContainer = styled.section``;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 2.5rem;

  @media (max-width: 825px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.25rem;
  }
`;

export default function AboutUsScene() {
  return (
    <Root className="montserrat-landing-page-font">
      <AboutUsContainer>
        <Heading style={{ marginTop: 0 }}>About us</Heading>
        <MemberWrapper>
          <MemberName>Dr. Marin Bozic</MemberName>
          <MemberRole>Founder</MemberRole>
          <MemberInfo>
            Dr. Marin Bozic is the founder and CEO of Bozic LLC, a global
            provider of technology solutions for commodity markets analytics and
            designer of three major livestock insurance programs in the United
            States - Dairy Revenue Protection, Livestock Gross Margin and
            Livestock Risk Protection. In addition, Dr. Bozic is a board advisor
            to Edge Dairy Farmer Cooperative and a nationally popular consultant
            and speaker on economics of dairy markets and policy issues.
          </MemberInfo>
        </MemberWrapper>
        <MemberWrapper>
          <MemberName>Brian Walton</MemberName>
          <MemberRole>Lead Economist</MemberRole>
          <MemberInfo>
            Brian Walton is an economics and research specialist with Bozic LLC.
            Brian's research specializes in data analysis, consulting and risk
            management in the dairy and grain sectors. He leads UDM business
            development, consulting, and customer service efforts for UDM. Brian
            earned his undergraduate degree from the University of Minnesota in
            applied economics.
          </MemberInfo>
        </MemberWrapper>
        <MemberWrapper>
          <MemberName>Ivan Roic</MemberName>
          <MemberRole>Lead Developer</MemberRole>
          <MemberInfo>
            Ivan Roic is the lead developer for UDM, assisting in data
            collection processes and the seamless performance of the UDM
            database/API. Ivan has over a decade of experience with procuring
            data, managing IT systems, and organizing large databases.
          </MemberInfo>
        </MemberWrapper>
      </AboutUsContainer>
      <WhoWeServeContainer>
        <Heading>Who we serve</Heading>
        <Grid>
          {whoWeServe.map((wws) => (
            <GridItem key={wws.title} gridItem={wws} />
          ))}
        </Grid>
      </WhoWeServeContainer>
    </Root>
  );
}

const GridItemContainer = styled.div`
  > * + * {
    margin-top: 1.25rem;
  }
`;
const GridItemIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const GridItemIcon = styled.div`
  background-color: ${color.PRIMARY};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  font-size: 3rem;
  padding: 1.5rem;

  @media (max-width: 815px) {
    font-size: 1.5rem;
    padding: 1rem;
  }
`;
const GridItemTitle = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2rem;
`;
const GridItemDescription = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;

  @media (max-width: 815px) {
    text-align: left;
  }
`;

type TGridItem = {
  icon: React.ReactNode;
  title: string;
  info: string;
};
function GridItem({ gridItem }: { gridItem: TGridItem }) {
  return (
    <GridItemContainer>
      <GridItemIconWrapper>
        <GridItemIcon>{gridItem.icon}</GridItemIcon>
      </GridItemIconWrapper>
      <GridItemTitle>{gridItem.title}</GridItemTitle>
      <GridItemDescription>{gridItem.info}</GridItemDescription>
    </GridItemContainer>
  );
}

const whoWeServe: Array<TGridItem> = [
  {
    icon: <FaCog />,
    title: "Processors",
    info:
      "Many processors use UDM because of the convenience of a one stop hub for relevant market data that helps drive better decisions",
  },
  {
    icon: <PiBank />,
    title: "Banks & Lenders",
    info:
      "Banks and agricultural lenders use UDM to help with fundamental market analysis and while performing stress testing scenarios",
  },
  {
    icon: <FaFileContract />,
    title: "Procurement Staff",
    info:
      "End users, buyers and procurement staff view UDM as integral to make more informed purchasing decisions",
  },
  {
    icon: <PiFactory />,
    title: "Cooperatives / Producers",
    info:
      "Many of the nations cooperatives rely on UDM for help in making important business decisions",
  },
  {
    icon: <LuGraduationCap />,
    title: "Universities",
    info:
      "Universities and governmental agencies utilize UDM to enrich research and policy decisions",
  },
  {
    icon: <GiTrade />,
    title: "Traders",
    info:
      "Traders, exporters, and brokers use UDM to assist in fundamental analysis and aid trading decisions across the globe",
  },
  {
    icon: <BsBank />,
    title: "Hedge Funds",
    info:
      "Proprietary trading groups, market makers, CTA's, and hedge funds rely on UDM for data needs in order to save time on data cleaning and collection while they focus on getting the edge in markets",
  },
  {
    icon: <VscGraph />,
    title: "Research / Advisory Firms",
    info:
      "Research firms use UDM to help automate research reports and provide data analytics and insights to their customers",
  },
  {
    icon: <IoShieldCheckmark />,
    title: "Insurance Companies",
    info:
      "Insurance companies and agencies use UDM to help sales and manage risk around livestock insurance related offerings",
  },
  {
    icon: <FaStore />,
    title: "Retail Chains / Grocers",
    info:
      "Retail chains and grocery chains use UDM to assist with planning and budgeting, allowing them to spend more time on core business activities",
  },
];
