import * as React from "react";
import styled from "styled-components";
import ProductScene from "./scenes/Product/ProductScene";
import color from "./utils/color";
import { Route } from "react-router";
import LoginPageScene from "./scenes/LoginPage/LoginPageScene";
import NavBarContainer from "./containers/NavBarContainer/NavBarContainer";
import userManager from "./ducks/authentication/userManager";
import { setAccessToken } from "./utils/api";
import DashboardScene from "./scenes/Dashboard/DashboardScene";
import CatalogMenuScene from "./scenes/Menu/CatalogMenuScene";
import MenuScene from "./scenes/Menu/MenuScene";
import * as Loadable from "react-loadable";
import { authActions } from "./ducks/authentication/actions";
import { AuthState } from "./ducks/authentication/reducers";
import { connect } from "react-redux";
import LandingPageScene from "./scenes/LandingPage/LandingPageScene";
import { RootState } from "./ducks/store";
import { getAuthState } from "./ducks/authentication/selectors";
import AdminPanelSkeleton from "./components/Skeletons/AdminPanelSkeleton";
import CatalogScene from "./scenes/Catalog/CatalogScene";
import RevenuePredictionScene from "./scenes/DairyRevenuePrediction/RevenuePredictionScene";
import ViewMailNotificationsScene from "./scenes/ViewMailNotifications/ViewMailNotificationsScene";
import UdmApiSubscriptionScene from "./scenes/LandingPage/UdmApiSubscriptionScene";
import ContactUsScene from "./scenes/LandingPage/ContactUsScene";
import AboutUsScene from "./scenes/LandingPage/AboutUsScene";

const AdminPanel = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "admin-panel" */ "./scenes/ProfileAdministration/ProfileAdministrationScene"
    ),
  loading: () => <AdminPanelSkeleton />,
  delay: 200,
  timeout: 10000,
});

const Root = styled.div`
  background-color: ${color.BACKGROUND_SECONDARY};
`;

const ContainerWrapper = styled.div`
  padding-left: 0;
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;
  max-width: 1400px;
  min-height: 100vh;
  background-color: ${color.BACKGROUND};
`;

const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 90px;
  padding-bottom: 40px;
  max-width: 1100px;
`;

const LandingPageScenesWrapper = styled.div`
  padding: 10rem 5rem 2.5rem;

  @media (max-width: 550px) {
    padding: 5rem 2.5rem 1.25rem;
  }
`;

type State = { isLoggedIn: boolean };

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

class App extends React.Component<Props, State> {
  public state = { isLoggedIn: false };

  public componentDidMount() {
    userManager.getUser().then((user) => {
      if (user) {
        this.setState({ isLoggedIn: true });
        setAccessToken(user.token_type, user.access_token);
        this.props.getAuthState();
        this.props.setExpireAt(user.expires_at);
      }
    });
  }

  public render() {
    if (
      this.props.auth === AuthState.initialization ||
      this.props.auth === AuthState.guest
    ) {
      return (
        <>
          <NavBarContainer isLoggedIn={this.state.isLoggedIn} />
          <Route path="/" exact component={LandingPageScene} />
          {location.pathname !== "/" && (
            <LandingPageScenesWrapper>
              <Route
                path="/udm-api-subscription"
                component={UdmApiSubscriptionScene}
              />
              <Route path="/contact-us" component={ContactUsScene} />
              <Route path="/about-us" component={AboutUsScene} />
            </LandingPageScenesWrapper>
          )}
        </>
      );
    }

    return (
      <Root>
        <NavBarContainer isLoggedIn={this.state.isLoggedIn} />
        <ContainerWrapper>
          <Container>
            <Content>
              <Route path="/product/:id" component={ProductScene} />
              <Route path="/menu/catalog" component={CatalogMenuScene} />
              <Route path="/login" component={LoginPageScene} />
              <Route path="/admin" component={AdminPanel} />
              <Route path="/catalog" component={CatalogScene} />
              <Route exact path="/menu/:id" component={MenuScene} />
              <Route path="/" exact component={DashboardScene} />
              <Route path="/drp" component={RevenuePredictionScene} />
              <Route
                path="/view-mail-notifications"
                component={ViewMailNotificationsScene}
              />
            </Content>
          </Container>
        </ContainerWrapper>
      </Root>
    );
  }
}

const mapDispatchToProps = {
  getAuthState: authActions.get.authState,
  setExpireAt: authActions.set.expiresAt,
};

const mapStateToProps = (state: RootState) => ({ auth: getAuthState(state) });

export default connect(mapStateToProps, mapDispatchToProps)(App);
