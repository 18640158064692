import * as React from "react";
import styled from "styled-components";
import color from "../../../utils/color";
import RightArrowIcon from "src/icons/RightArrowIcon";
import LeftArrowIcon from "src/icons/LeftArrowIcon";
import numberRange from "../../../utils/numberRange";

const NumberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const NumberBox = styled.div<{ isSelected?: boolean; isNumber?: boolean }>`
  height: 35px;
  text-align: center;
  line-height: 35px;
  color: ${({ isSelected }) =>
    isSelected ? color.PRIMARY : color.ICONS_HEADER};
  font-size: 14px;
  width: 35px;
  font-weight: 600;
  background-color: ${({ isNumber }) =>
    isNumber ? color.BACKGROUND_SECONDARY : color.BACKGROUND};
  border-radius: 3px;
  margin-right: 5px;
  cursor: ${({ isNumber, isSelected }) =>
    isSelected ? "default" : isNumber ? "pointer" : "default"};

  &:last-child {
    margin-right: 0;
  }
`;

const IconsWrapper = styled.div`
  height: 35px;
  width: 35px;
  transform: translateY(3px);
  color: ${color.NEUTRAL};
  font-size: 30px;
  cursor: pointer;
`;

type Props = {
  dataLength: number;
  perPage: number;
  activePage: number;
  onClick(value: number): () => void;
};

type State = { lastPage: number };

class Pagination extends React.Component<Props, State> {
  public state = {
    lastPage: Math.ceil(this.props.dataLength / this.props.perPage),
  };

  public componentDidUpdate(prevProps: Props) {
    if (
      prevProps.dataLength !== this.props.dataLength ||
      prevProps.perPage !== this.props.perPage
    ) {
      this.setState({
        lastPage: Math.ceil(this.props.dataLength / this.props.perPage),
      });
    }
  }

  public render() {
    const { dataLength, perPage } = this.props;

    return (
      dataLength > perPage && (
        <NumberBoxWrapper>
          {this.arrows(true)}
          {this.firstPage()}
          {this.dots(true)}
          {this.pageNumbers()}
          {this.dots(false)}
          {this.lastPage()}
          {this.arrows(false)}
        </NumberBoxWrapper>
      )
    );
  }

  private arrows = (leftSide: boolean) => {
    const { activePage, onClick } = this.props;
    const { lastPage } = this.state;
    if (activePage > 1 && leftSide) {
      return (
        <IconsWrapper>
          <LeftArrowIcon onClick={onClick(activePage - 1)} />
        </IconsWrapper>
      );
    }
    if (activePage < lastPage && !leftSide) {
      return (
        <IconsWrapper>
          <RightArrowIcon onClick={onClick(activePage + 1)} />
        </IconsWrapper>
      );
    }
    return <NumberBox />;
  };

  private dots = (leftSide: boolean) => {
    const { activePage, dataLength, perPage } = this.props;
    const { lastPage } = this.state;
    if (!leftSide && activePage + 4 <= lastPage && dataLength / perPage > 5) {
      return <NumberBox>...</NumberBox>;
    }
    if (leftSide && activePage > 4 && lastPage !== 5) {
      return <NumberBox>...</NumberBox>;
    }
    return null;
  };

  private firstPage = () => {
    const { lastPage } = this.state;
    const { activePage, onClick } = this.props;
    if (activePage > 4 && lastPage !== 5) {
      const firstPage = 1;
      return (
        <NumberBox
          onClick={onClick(firstPage)}
          isSelected={firstPage === activePage}
          isNumber
        >
          {firstPage}
        </NumberBox>
      );
    }
    return null;
  };

  private lastPage = () => {
    const { activePage, onClick, dataLength, perPage } = this.props;
    const { lastPage } = this.state;
    if (activePage + 4 <= lastPage && dataLength / perPage > 5) {
      return (
        <NumberBox
          onClick={onClick(lastPage)}
          isNumber
          isSelected={lastPage === activePage}
        >
          {lastPage}
        </NumberBox>
      );
    }
    return null;
  };

  private pageNumbers = () => {
    const { lastPage } = this.state;
    const { onClick, dataLength, perPage, activePage } = this.props;
    let pageNumberRange: number[] = [];
    if (
      activePage > 4 &&
      activePage + 4 <= lastPage &&
      dataLength / perPage > 5
    ) {
      pageNumberRange = numberRange(activePage - 1, activePage + 2);
    } else if (activePage <= 4) {
      let pages = dataLength / perPage;
      if (pages >= 5) {
        pageNumberRange = numberRange(1, 6);
      } else if (dataLength % perPage !== 0) {
        pages = pages + 1;
        pageNumberRange = numberRange(1, Math.ceil(pages));
      }
    } else if (activePage + 4 >= lastPage) {
      pageNumberRange = numberRange(lastPage - 4, lastPage + 1);
    }
    return pageNumberRange.map((pageNumber, index) => (
      <NumberBox
        key={index}
        onClick={onClick(pageNumber)}
        isSelected={pageNumber === activePage}
        isNumber
      >
        {pageNumber}
      </NumberBox>
    ));
  };
}

export default Pagination;
