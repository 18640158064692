import styled from 'styled-components';
import color from '../../../utils/color';

const SelectSection = styled.div`
  display: flex;
  align-items: baseline;
  padding: 8px 0;
  margin: 30px 0;
  color: ${color.PRIMARY};
  border-bottom: ${color.NEUTRAL} solid 2px;

  span {
    width: 70px;
  }
`;

export default SelectSection;
