import * as React from "react";
import { NavLink } from "react-router-dom";
import color from "src/utils/color";
import styled from "styled-components";

const PublicNavLinks = () => (
  <NavBarLinkWrapper className="montserrat-landing-page-font">
    <LandingPageNavBarLink
      to="/udm-api-subscription"
      activeClassName="active-route"
    >
      UDM API Subscription
    </LandingPageNavBarLink>
    <LandingPageNavBarLink to="/contact-us" activeClassName="active-route">
      Contact Us
    </LandingPageNavBarLink>
    <LandingPageNavBarLink to="/about-us" activeClassName="active-route">
      About Us
    </LandingPageNavBarLink>
  </NavBarLinkWrapper>
);

export default PublicNavLinks;

const NavBarLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2rem;

  .active-route {
    color: ${color.PRIMARY};
  }
`;

const LandingPageNavBarLink = styled(NavLink)`
  text-decoration: none;
  color: ${color.TEXT_PRIMARY};
  font-size: 1rem;
  font-weight: bold;

  &:hover {
    color: ${color.PRIMARY};
  }

  &:active {
    color: ${color.POSITIVE};
  }
`;
