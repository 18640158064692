import Axios from "axios";

const api = Axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_API_URL}/admin/`,
  timeout: 20000,
});

const adminAPI = {
  fetchSubscriptions: () =>
    api.get("/notification/view/subscription", { params: { active: true } }),
  fetchAllViews: () => api.get("/notification/view"),
  subscribeToView: (viewName: string) =>
    api.post("/notification/view/subscription", { viewName }),
  unsubscribeToView: (viewName: string) =>
    api.delete(`/notification/view/subscription/${viewName}`),
};

export default adminAPI;
