import styled from "styled-components";
import CheckboxIcon from "../../../icons/CheckboxIcon";
import color from "../../../utils/color";

type Props = {
  selected?: boolean;
  skipmarginright?: "true" | "false";
  disabled?: boolean;
};

const Checkbox = styled(CheckboxIcon)<Props>`
  &&& {
    color: ${({ selected = false }) =>
      selected ? color.PRIMARY : color.NEUTRAL_DARK};
    font-size: 20px;
    transform: translateY(4px);
    margin-right: ${({ skipmarginright = "false" }) =>
      skipmarginright === "true" ? "0" : "15px"};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  }
`;

export default Checkbox;
