import { ActionType, createStandardAction } from "typesafe-actions";

export const adminPageActions = {
  setSearch: createStandardAction("@adminPage/SET_SEARCH")<string>(),
  setOrganization: createStandardAction("@adminPage/SET_ORGANIZATION")<
    string
  >(),
};

export type AdminPageActions = ActionType<typeof adminPageActions>;
