import { PeriodFrequency } from "../../../types/PeriodFrequency";
import { ProductDto } from "../../../types/dto/ProductDto";
import { AxiosResponse } from "axios";

const filterAreaFrequencies = (frequency: PeriodFrequency) => (areaDto: any) =>
  areaDto.frequency.name === frequency;

const mapAreaFrequencies = ({ area: { id, name } }: any) => ({ id, name });

const mapProductDtoToProduct = ({ data }: AxiosResponse<ProductDto>) => {
  const {
    unit,
    report,
    id,
    name,
    nameTranslated,
    attributes,
    updateSchedule,
    areaFrequencys: areas,
    scale,
  } = data;

  if (process.env.NODE_ENV !== "production") {
    if (!report) {
      throw new Error(`"report" is missing in "product" data`);
    }
    if (!areas.length) {
      throw new Error(`"areaFrequencys" are missing in "product" data`);
    }
    if (!attributes.length) {
      throw new Error(`"attributes" are missing in "product" data`);
    }
  } else {
    if (!report || !areas.length || !attributes.length) {
      throw new Error(`No data available for selected report.`);
    }
  }

  const {
    yoYChange,
    yoYChangePercentage,
    ytDChange,
    ytDPercentageChange,
    doDChange,
    doDChangePercentage,
    woWChange,
    woWPercentageChange,
    moMChange,
    moMChangePercentage,
    qoQChange,
    qoQChangePercentage,
    metaFields,
    lastReport,
    lastReportValue,
    lastReportPeriod,
    firstReportPeriod,
  } = report;

  const frequencies = {
    Daily: areas.filter(filterAreaFrequencies("Daily")).map(mapAreaFrequencies),
    Weekly: areas
      .filter(filterAreaFrequencies("Weekly"))
      .map(mapAreaFrequencies),
    Monthly: areas
      .filter(filterAreaFrequencies("Monthly"))
      .map(mapAreaFrequencies),
    Quarterly: areas
      .filter(filterAreaFrequencies("Quarterly"))
      .map(mapAreaFrequencies),
    Annual: areas
      .filter(filterAreaFrequencies("Annual"))
      .map(mapAreaFrequencies),
  };

  return {
    product: { id, name, nameTranslated },
    scale,
    report: {
      id: report.id,
      name: report.name,
      nameTranslated: report.nameTranslated,
    },
    publisher: report.publisher,
    summary: {
      lastReportValue,
      lastReport: new Date(lastReport),
      lastReportPeriod: new Date(lastReportPeriod),
      firstReportPeriod: new Date(firstReportPeriod),
      yoYChange,
      yoYChangePercentage,
      ytDChange,
      ytDPercentageChange,
      doDChange,
      doDChangePercentage,
      woWChange,
      woWPercentageChange,
      moMChange,
      moMChangePercentage,
      qoQChange,
      qoQChangePercentage,
    },
    areas: {
      defaultArea: {
        id: report.areaFrequencys[0].area.id,
        name: report.areaFrequencys[0].area.name,
        frequency: report.areaFrequencys[0].frequency.name,
      },
      ...frequencies,
      availableFrequencies: Object.keys(frequencies).filter(
        (key) => frequencies[key as keyof typeof frequencies].length
      ),
    },
    metaFields,
    unit: { id: unit.id, name: unit.unit, description: unit.description },
    updates: {
      calendarUrl: report.calendarUrl,
      updateSchedule: updateSchedule.map((field) => ({
        releaseID: field.releaseId,
        date: new Date(field.releaseTime),
      })),
    },
    // non changed values
    attributes,
    // data,
  };
};

export default mapProductDtoToProduct;
