import styled from 'styled-components';
import color from '../../../utils/color';

const Input = styled.input<{ error?: boolean }>`
  width: calc(100% - 10px);
  font-size: 15px;
  border-radius: 5px;
  padding: 5px;
  transform: translateY(3px);
  border: ${({error = false}) => error ? `1px solid ${color.NEGATIVE}` : 'none'};
  background-color: ${color.BACKGROUND_SECONDARY};
  outline: none;

  &:focus {
    outline: none;
    box-shadow: ${({ error }) => error ? null : `0 0 4px 1px ${color.PRIMARY}`};
  }
`;

export default Input;
