import * as React from "react";
import styled from "styled-components";
import color from "src/utils/color";
import { connect } from "react-redux";
import { menuActions } from "../../../ducks/menu/actions";
import { RootState } from "../../../ducks/store";
import { getNavMenuItems } from "../../../ducks/menu/selectors";
import { RouteComponentProps, withRouter } from "react-router";
import { NavMenuDto } from "../../../types/dto/NavMenuDto";
import { getAuthState } from "src/ducks/authentication/selectors";

const ButtonContainer = styled.div`
  display: flex;
  gap: 2.5rem;
`;

const ModuleButton = styled.div<{ isSelected: boolean }>`
  font-size: 0.9rem;
  text-align: center;
  cursor: pointer;
  border-bottom: ${({ isSelected }) =>
    isSelected && `${color.PRIMARY} solid 2px`};
  box-shadow: ${({ isSelected }) =>
    isSelected && `0 5px 5px -4px ${color.PRIMARY}`};
  margin-bottom: ${({ isSelected }) => isSelected && "-2px"};

  &:hover {
    border-bottom: ${color.PRIMARY} solid 2px;
    box-shadow: 0 5px 5px -4px ${color.PRIMARY};
    margin-bottom: -2px;
  }
`;

type State = { selectedMenuItem: number };

type Props = RouteComponentProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

class NavBarContent extends React.Component<Props, State> {
  public state = { selectedMenuItem: -1 };

  public componentDidMount() {
    this.props.getNavMenuItems();
    const separatedURL = this.props.location.pathname.split("/");
    if (
      this.props.location.pathname === "/menu" ||
      this.props.location.pathname === "/menu/"
    ) {
      this.setState({ selectedMenuItem: 1 });
      this.props.history.push("/menu/1");
    } else if (this.props.location.pathname === "/catalog") {
      this.setState({ selectedMenuItem: this.props.navMenuItems.length });
    } else if (this.props.location.pathname === "/view-mail-notifications") {
      this.setState({ selectedMenuItem: this.props.navMenuItems.length + 1 });
    } else if (separatedURL[1] === "menu") {
      this.setState({ selectedMenuItem: Number(separatedURL[2]) });
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      prevProps !== this.props &&
      !(
        this.props.location.pathname.includes("/menu") ||
        this.props.location.pathname.includes("/catalog") ||
        this.props.location.pathname.includes("/view-mail-notifications")
      )
    ) {
      this.setState({ selectedMenuItem: -1 });
    }
    if (
      prevProps.navMenuItems.length !== this.props.navMenuItems.length &&
      this.props.location.pathname.includes("/catalog")
    ) {
      this.setState({ selectedMenuItem: this.props.navMenuItems.length });
    }
    if (
      prevProps.navMenuItems.length !== this.props.navMenuItems.length &&
      this.props.location.pathname.includes("/view-mail-notifications")
    ) {
      this.setState({ selectedMenuItem: this.props.navMenuItems.length + 1 });
    }
  }

  public render() {
    const { selectedMenuItem } = this.state;
    const { navMenuItems } = this.props;

    const catalogBetaIndex = navMenuItems.length;
    const viewMailNotifsIndex = catalogBetaIndex + 1;

    return (
      <ButtonContainer className="montserrat-landing-page-font">
        {navMenuItems.map((nmi) => (
          <ModuleButton
            key={nmi.id}
            onClick={this.selectedMenuItemHandler(nmi)}
            isSelected={nmi.id === selectedMenuItem}
          >
            {nmi.text}
          </ModuleButton>
        ))}
        <ModuleButton
          onClick={this.onCatalogClick}
          isSelected={catalogBetaIndex === selectedMenuItem}
        >
          Catalog Beta
        </ModuleButton>
        <ModuleButton
          onClick={this.onViewMailNotifsClick}
          isSelected={viewMailNotifsIndex === selectedMenuItem}
        >
          View Mail Notifications
        </ModuleButton>
      </ButtonContainer>
    );
  }

  private onCatalogClick = () => {
    this.setState({ selectedMenuItem: this.props.navMenuItems.length });
    this.props.history.push(`/catalog`);
  };

  private onViewMailNotifsClick = () => {
    this.setState({ selectedMenuItem: this.props.navMenuItems.length + 1 });
    this.props.history.push(`/view-mail-notifications`);
  };

  private selectedMenuItemHandler = (item: NavMenuDto) => () => {
    this.setState({ selectedMenuItem: item.id });
    this.props.setSelectedMenu(item);
    this.props.history.push(`/menu/${item.id}`);
  };
}

const mapStateToProps = (state: RootState) => ({
  auth: getAuthState(state),
  navMenuItems: getNavMenuItems(state),
});

const mapDispatchToProps = {
  getNavMenuItems: menuActions.navMenu.initialize,
  setSelectedMenu: menuActions.navMenu.setSelectedMenu,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavBarContent)
);
