import { ActionType, getType } from "typesafe-actions";
import { adminActions } from "../actions";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import organizationAPI from "../../../services/organizationServices";
import { AxiosResponse } from "axios";
import { OrganizationUserDto } from "../../../types/dto/OrganizationUserDto";

export function* getOrganizationUsers(
  action: ActionType<typeof adminActions.organizationUsers.getUsers>
) {
  try {
    const users: AxiosResponse<OrganizationUserDto[]> = yield call(
      organizationAPI.fetchOrganizationUsers,
      action.payload
    );
    yield put(
      adminActions.organizationUsers.saveUsers({
        organizationName: action.payload,
        users: users.data,
      })
    );
  } catch (error) {
    toast.error(error.message, { position: "top-center" });
  }
}

export function* addOrganizationUser(
  action: ActionType<typeof adminActions.organizationUsers.addUser>
) {
  try {
    const { organizationName, user } = action.payload;
    yield call(
      organizationAPI.addOrganizationUser,
      organizationName,
      user.userName
    );
    yield put(
      adminActions.organizationUsers.saveUser({ organizationName, user })
    );
  } catch (error) {
    toast.error(error.message, { position: "top-right" });
  }
}

export function* deleteOrganizationUser(
  action: ActionType<typeof adminActions.organizationUsers.removeUser>
) {
  try {
    const { organizationName, userName } = action.payload;
    yield call(
      organizationAPI.deleteOrganizationUser,
      organizationName,
      userName
    );
    yield put(
      adminActions.organizationUsers.deleteUser({ organizationName, userName })
    );
  } catch (error) {
    toast.error(error.message, { position: "top-right" });
  }
}

export function* addUserRole(
  action: ActionType<typeof adminActions.organizationUsers.addUserRole>
) {
  try {
    const { organizationName, userName, role } = action.payload;
    yield call(organizationAPI.addUserRole, organizationName, userName, role);
    yield put(
      adminActions.organizationUsers.addAdminUserRole({
        organizationName,
        userName,
        role,
      })
    );
    yield put(
      adminActions.organizationUsers.getUsers(action.payload.organizationName)
    );
  } catch (error) {
    toast.error(error.message, { position: "top-right" });
  }
}

export function* removeUserRole(
  action: ActionType<typeof adminActions.organizationUsers.removeUserRole>
) {
  try {
    const { organizationName, userName, role } = action.payload;
    yield call(
      organizationAPI.removeUserRole,
      organizationName,
      userName,
      role
    );
    yield put(
      adminActions.organizationUsers.removeAdminUserRole({
        organizationName,
        userName,
      })
    );
    yield put(
      adminActions.organizationUsers.getUsers(action.payload.organizationName)
    );
  } catch (error) {
    toast.error(error.message, { position: "top-right" });
  }
}

export function* addAdminUser(
  action: ActionType<typeof adminActions.organizationUsers.addAdminUser>
) {
  try {
    const { organizationName, user } = action.payload;
    yield call(
      organizationAPI.addOrganizationUser,
      organizationName,
      user.userName
    );
    yield call(
      organizationAPI.addUserRole,
      organizationName,
      user.userName,
      "admin"
    );
    yield put(adminActions.organizationUsers.saveUser(action.payload));
    yield put(adminActions.organizationUsers.getUsers(organizationName));
  } catch (error) {
    toast.error(error.message, { position: "top-right" });
  }
}

export function* organizationUsersSagaWatcher() {
  yield all([
    takeLatest(
      getType(adminActions.organizationUsers.getUsers),
      getOrganizationUsers
    ),
    takeLatest(
      getType(adminActions.organizationUsers.addUser),
      addOrganizationUser
    ),
    takeLatest(
      getType(adminActions.organizationUsers.removeUser),
      deleteOrganizationUser
    ),
    takeLatest(
      getType(adminActions.organizationUsers.addUserRole),
      addUserRole
    ),
    takeLatest(
      getType(adminActions.organizationUsers.removeUserRole),
      removeUserRole
    ),
    takeLatest(
      getType(adminActions.organizationUsers.addAdminUser),
      addAdminUser
    ),
  ]);
}

export default organizationUsersSagaWatcher;
