import * as React from "react";
import color from "src/utils/color";
import styled from "styled-components";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
} from "react-icons/io";
import Button from "src/components/shared/Button/Button";
import { Link, RouteComponentProps } from "react-router-dom";
import { CategoryRouteState, RouteCategories } from "./landingPage.types";
import noSectionImg from "../../media/images/no-section-selected.png";
import dairySectionImg from "../../media/images/dairy-section.png";
import grainFeedSectionImg from "../../media/images/grain-feed-section.png";
import livestockSectionImg from "../../media/images/livestock-section.png";

type PageProps = RouteComponentProps<{}, {}, CategoryRouteState | undefined>;

const Root = styled.section`
  color: ${color.TEXT_PRIMARY};

  > * + * {
    margin-top: 5rem;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Heading = styled.h1`
  font-size: 48px;
  line-height: 58.51px;
  margin-top: 0;
`;
const HeadingDescription = styled.p`
  width: 675px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5rem;
  letter-spacing: 0.025rem;

  @media (max-width: 675px) {
    width: 100%;
  }
`;

const Highlight = styled.span`
  color: ${color.PRIMARY};
`;

const InfoSectionWrapper = styled.div``;
const SubHeading = styled.h2`
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.75rem;
`;
const InfoSection = styled.p`
  line-height: 1.5rem;
  font-weight: 500;
  font-size: 1rem;

  @media (min-width: 1024px) {
    width: 75%;
  }
`;

const ServicesWrapper = styled.div`
  display: flex;
  column-gap: 10rem;

  @media (max-width: 870px) {
    flex-direction: column;
    row-gap: 5rem;
  }
`;
const ServicesDescriptionContainer = styled.section`
  flex: 1 1 0%;
`;
const ServicesImageContainer = styled.div`
  flex: 1 1 0%;

  @media (max-width: 870px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const ServicesImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
`;
const WhatWeCoverDescription = styled.p`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.2rem;
`;

const CoveredServicesWrapper = styled.section`
  padding-top: 2rem;

  > * + * {
    margin-top: 0.5rem;
  }
`;

const FooterActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 2.5rem;
  margin-bottom: 5rem;
`;
const FooterNote = styled.p`
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: ${color.LANDING_PAGE_SECONDARY};
`;

type ActiveSections = {
  "dairy-markets": boolean;
  "grain-feed-markets": boolean;
  "livestock-markets": boolean;
};
const DEFAULT_ACTIVE_SECTION: ActiveSections = {
  "dairy-markets": false,
  "grain-feed-markets": false,
  "livestock-markets": false,
};

export default function UdmApiSubscriptionScene({ location }: PageProps) {
  const [activeSection, setActiveSection] = React.useState<ActiveSections>(
    DEFAULT_ACTIVE_SECTION
  );

  function getSectionImageSource() {
    if (activeSection["dairy-markets"]) return dairySectionImg;
    if (activeSection["grain-feed-markets"]) return grainFeedSectionImg;
    if (activeSection["livestock-markets"]) return livestockSectionImg;
    return noSectionImg;
  }

  function handleServiceClick(service: RouteCategories) {
    setActiveSection((current) => {
      const newState = { ...DEFAULT_ACTIVE_SECTION };
      newState[service] = !current[service];
      return newState;
    });
  }

  React.useEffect(() => {
    if (location.state) {
      const { section } = location.state;
      setActiveSection((current) => ({ ...current, [section]: true }));
      const activeSectionDOM = document.getElementById(section);
      if (activeSectionDOM) {
        activeSectionDOM.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }
  }, []);

  return (
    <Root className="montserrat-landing-page-font">
      <HeadingWrapper>
        <Heading>
          UDM <Highlight>API</Highlight> Subscription
        </Heading>
        <HeadingDescription>
          Navigate the markets with unmatched accuracy. UDM eliminates data
          hassles by aggregating and streamlining the process of cleaning and
          collecting information from a broad range of global sources. Our
          service delivers timely data, empowering you to make informed
          decisions and stay ahead.
        </HeadingDescription>
      </HeadingWrapper>
      <InfoSectionWrapper>
        <SubHeading>What we do</SubHeading>
        <InfoSection>
          This annual subscription-based service provides users with access to a
          comprehensive database covering dairy, livestock, and grain markets,
          agricultural insurance data, and macroeconomic data aggregated from
          over 700 reports worldwide. It serves as a one-stop shop for
          agricultural market data, saving time, money, and hassle for a wide
          range of industry participants.
        </InfoSection>
      </InfoSectionWrapper>
      <ServicesWrapper>
        <ServicesDescriptionContainer>
          <SubHeading style={{ marginTop: 0 }}>What we cover</SubHeading>
          <WhatWeCoverDescription>
            Explore the different markets for which we provide our API data
            service. Users can also interact with the data in Microsoft Excel
            and Power BI, creating customized dashboards to meet their specific
            needs
          </WhatWeCoverDescription>
          <CoveredServicesContainer
            activeSection={activeSection}
            handleServiceClick={handleServiceClick}
          />
        </ServicesDescriptionContainer>
        <ServicesImageContainer>
          <ServicesImage src={getSectionImageSource()} />
        </ServicesImageContainer>
      </ServicesWrapper>
      <footer>
        <FooterActionsWrapper>
          <Link to="/contact-us">
            <Button type="button" filled>
              Contact Us
            </Button>
          </Link>
        </FooterActionsWrapper>
        <FooterNote>
          *If you're a student, faculty member, or part of a governmental agency
          you could be provided with a UDM subscription at no cost
        </FooterNote>
      </footer>
    </Root>
  );
}

type CoveredServicesContainerProps = {
  activeSection: ActiveSections;
  handleServiceClick(service: RouteCategories): void;
};
function CoveredServicesContainer({
  activeSection,
  handleServiceClick,
}: CoveredServicesContainerProps) {
  return (
    <CoveredServicesWrapper>
      {coveredServices.map((cs) => (
        <Service
          key={cs.id}
          coveredService={cs}
          isOpen={activeSection[cs.category]}
          handleServiceClick={handleServiceClick}
        />
      ))}
    </CoveredServicesWrapper>
  );
}

const CoveredService = styled.div`
  border-bottom: 2px solid black;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ServiceButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color.PRIMARY};
  transition: color 300ms linear;
  cursor: pointer;

  &:hover {
    color: ${color.POSITIVE};
  }
`;
const ServiceDescription = styled.p`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
`;
const CoveredServiceTitle = styled.span`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
`;

type TCoveredService = {
  id: number;
  title: string;
  description: string;
  category: RouteCategories;
};
type ServiceProps = {
  coveredService: TCoveredService;
  isOpen: boolean;
  handleServiceClick(service: RouteCategories): void;
};
function Service({ coveredService, isOpen, handleServiceClick }: ServiceProps) {
  return (
    <>
      <CoveredService id={coveredService.category}>
        <CoveredServiceTitle>{coveredService.title}</CoveredServiceTitle>
        <ServiceButton
          type="button"
          onClick={() => handleServiceClick(coveredService.category)}
        >
          {isOpen ? (
            <IoIosArrowDropupCircle size="2rem" />
          ) : (
            <IoIosArrowDropdownCircle size="2rem" />
          )}
        </ServiceButton>
      </CoveredService>
      {isOpen && (
        <ServiceDescription>{coveredService.description}</ServiceDescription>
      )}
    </>
  );
}

const coveredServices: Array<TCoveredService> = [
  {
    id: 1,
    title: "Dairy Markets",
    description:
      "UDM was developed to address the dairy industry's need for a reliable hub for dairy market data. UDM offers a comprehensive range of dairy market information, including milk production, global trade flows, futures, options, cash market prices, stocks, and various other indicators.",
    category: "dairy-markets",
  },
  {
    id: 2,
    title: "Grain & Feed Markets",
    description:
      "UDM has a wide range of grain information ranging from supply, demand, exports, futures, options, exports, feedstuff prices, and many more indicators.",
    category: "grain-feed-markets",
  },
  {
    id: 3,
    title: "Livestock Markets",
    description:
      "UDM offers cattle and swine market information and livestock insurance related market data for DRP, LRP, and LGM.",
    category: "livestock-markets",
  },
];
