import * as React from "react";
import { connect } from "react-redux";
import C from "src/components/AdminPanel/adminPanel/C";
import HeaderCell from "src/components/AdminPanel/adminPanel/HeaderCell";
import Table from "src/components/AdminPanel/adminPanel/Table";
import TD from "src/components/AdminPanel/adminPanel/TD";
import Checkbox from "src/components/shared/Checkbox/Checkbox";
import { adminActions } from "src/ducks/admin/actions";
import {
  getActiveSubscriptions,
  getSubsAndViews,
  getViewsForDisplay,
} from "src/ducks/admin/selectors";
import { RootState } from "src/ducks/store";

type OwnProps = {
  perPage: number;
  activePage: number;
  sort: string;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  OwnProps;

function ViewMailNotificationsTable(props: Props) {
  function getNotifSelectedStatus(organizationViewName: string) {
    return props.activeSubscriptions.includes(organizationViewName);
  }

  function onReceiveClickHandler(
    organizationViewName: string,
    isSelected: boolean
  ) {
    return () => {
      if (isSelected) {
        const existingSubscription = props.subsAndViews.subscriptions.find(
          (sub) => sub.viewName === organizationViewName
        );
        if (!existingSubscription) return;
        props.unsubscribeToView(existingSubscription);
      } else props.subscribeToView({ viewName: organizationViewName });
    };
  }

  return (
    <Table>
      <thead>
        <tr>
          <HeaderCell>Name</HeaderCell>
          <HeaderCell textAlign="center">Receive Notification</HeaderCell>
        </tr>
      </thead>
      <tbody>
        {props.displayViews.length ? (
          props.displayViews.map((viewName) => (
            <tr key={viewName}>
              <TD maxWidth="525px">{viewName}</TD>
              <TD>
                <C>
                  <Checkbox
                    skipmarginright="true"
                    selected={getNotifSelectedStatus(viewName)}
                    onClick={onReceiveClickHandler(
                      viewName,
                      getNotifSelectedStatus(viewName)
                    )}
                  />
                </C>
              </TD>
            </tr>
          ))
        ) : (
          <tr>
            <TD>No views.</TD>
            <TD></TD>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  subsAndViews: getSubsAndViews(state),
  activeSubscriptions: getActiveSubscriptions(state),
  displayViews: getViewsForDisplay(state, props),
});

const mapDispatchToProps = {
  subscribeToView: adminActions.subscriptionsAndViews.subscribe,
  unsubscribeToView: adminActions.subscriptionsAndViews.unsubscribe,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewMailNotificationsTable);
