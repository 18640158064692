import produce from "immer";
import { adminActions, AdminActions } from "../actions";
import { getType } from "typesafe-actions";
import { View } from "../../../types/View";
import { OrganizationView } from "../../../types/OrganizationView";
import createUniqueArray from "../../../utils/createUniqueArray";

type InitialState = {
  allViews: View[];
  organizationViews: {
    [key: string]: OrganizationView[];
  };
};

const initialState: InitialState = {
  allViews: [],
  organizationViews: {},
};

export const viewReducer = produce<InitialState, AdminActions>(
  (state, action) => {
    switch (action.type) {
      case getType(adminActions.view.add):
        state.allViews = action.payload;
        return;
      case getType(adminActions.organizationView.addAll):
        state.organizationViews[action.payload.organizationName] =
          action.payload.view;
        return;
      case getType(adminActions.organizationView.add):
        const { organizationName } = action.payload;
        state.organizationViews[organizationName] = [
          ...state.organizationViews[organizationName],
          {
            organizationName: action.payload.organizationName,
            viewName: action.payload.view.name,
          },
        ];
        return;
      case getType(adminActions.organizationView.remove):
        const orgName = action.payload.organizationName;
        state.organizationViews[orgName] = state.organizationViews[
          orgName
        ].filter((view) => view.viewName !== action.payload.view.name);
        return;
      case getType(adminActions.organizationView.removeOrganization):
        delete state.organizationViews[action.payload];
        return;
      case getType(adminActions.organizationView.saveAllViews):
        const allViewsWithoutRequestAccess: OrganizationView[] = [];
        state.allViews.forEach((view) => {
          if (!view.requestAccess) {
            allViewsWithoutRequestAccess.push({
              organizationName: action.payload,
              viewName: view.name,
            });
          }
          state.organizationViews[action.payload] = createUniqueArray<
            OrganizationView
          >([
            ...allViewsWithoutRequestAccess,
            ...state.organizationViews[action.payload],
          ]);
        });
        return;
      default:
        return;
    }
  },
  initialState
);
