import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { all, call, put, takeLatest } from "redux-saga/effects";
import adminAPI from "src/services/adminServices";
import { Subscription } from "src/types/Subscription";
import { adminActions } from "../actions";
import { ActionType, getType } from "typesafe-actions";
import { authSaga } from "src/ducks/authentication/getAuthStatus";

export function* getViews() {
  try {
    const views: AxiosResponse<Array<string>> = yield call(
      adminAPI.fetchAllViews
    );
    yield put(adminActions.subscriptionsAndViews.populateViews(views.data));
  } catch (error) {
    toast.error(`${error.message}: Failed to fetch views.`);
  }
}

export function* getSubscriptions() {
  try {
    const subscriptions: AxiosResponse<Array<Subscription>> = yield call(
      adminAPI.fetchSubscriptions
    );
    yield put(
      adminActions.subscriptionsAndViews.populateSubs(subscriptions.data)
    );
  } catch (error) {
    toast.error(`${error.message}: Failed to fetch subscriptions.`);
  }
}

export function* subscribeToView(
  action: ActionType<typeof adminActions.subscriptionsAndViews.subscribe>
) {
  const viewName = action.payload.viewName;
  try {
    const newSub: AxiosResponse<Subscription> = yield call(
      adminAPI.subscribeToView,
      viewName
    );
    yield put(adminActions.subscriptionsAndViews.addSub(newSub.data));
  } catch (error) {
    yield put(adminActions.subscriptionsAndViews.removeSub(viewName));
    toast.error(error.message);
  }
}

export function* unsubscribeToView(
  action: ActionType<typeof adminActions.subscriptionsAndViews.unsubscribe>
) {
  try {
    yield put(
      adminActions.subscriptionsAndViews.removeSub(action.payload.viewName)
    );
    yield call(adminAPI.unsubscribeToView, action.payload.viewName);
  } catch (error) {
    yield put(adminActions.subscriptionsAndViews.addSub(action.payload));
    toast.error(error.message);
  }
}

export function* subscriptionSagaWatcher() {
  yield all([
    takeLatest(
      getType(adminActions.subscriptionsAndViews.getSubs),
      authSaga(getSubscriptions)
    ),
    takeLatest(
      getType(adminActions.subscriptionsAndViews.subscribe),
      authSaga(subscribeToView)
    ),
    takeLatest(
      getType(adminActions.subscriptionsAndViews.unsubscribe),
      authSaga(unsubscribeToView)
    ),
    takeLatest(
      getType(adminActions.subscriptionsAndViews.getViews),
      authSaga(getViews)
    ),
  ]);
}

export default subscriptionSagaWatcher;
